import type { FC } from 'react'

import { useState, useCallback, ReactNode, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { Typography } from '@/shared/components/typography'
import { Button } from '@/shared/components/button'
import { useUserDataSSEEvents } from '@/shared/hooks/use-user-data-sse-events'
import { formatLocalizationString } from '@/shared/utils'
import { usePaymentContext } from '@/shared/contexts/payment-context'
import { useLanguage } from '@/shared/contexts/language-context'
import {
  PAYMENT_CONFIRM_QR_PAGE,
  PAYMENT_FAILED_PAGE,
  PAYMENT_SUCCESS_PAGE,
} from '@/shared/constants/routes'
import { apiService } from '@/shared/api'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { usePaymentTokenContext } from '@/shared/contexts/token-context'
import { Headers } from '@/shared/constants/headers'
import { CurrencyMatcher } from '@/shared/constants/currency-matcher'
import { ReactComponent as EllipseWhiteIcon } from '@/shared/icons/ellipse-white-icon.svg'
import { ReactComponent as VisaIcon } from '@/shared/icons/color-visa-icon.svg'
import { ReactComponent as MastercardIcon } from '@/shared/icons/color-mastercard-icon.svg'
import { ReactComponent as MirIcon } from '@/shared/icons/color-mir-icon.svg'

import styles from './payment-confirm.module.scss'
import { defaultPaymentConfirmationRequiredHandler } from '@/shared/utils/default-sse-handlers'
import { amountParser } from '@/shared/utils/amount-parser'
import { useMediaContext } from '@/shared/contexts/media-context/media-context'
import { AxiosError } from 'axios'

export const PaymentConfirm: FC = () => {
  const navigate = useNavigate()

  const {
    sessionData,
    contractId,
    cardForPayment,
    eventSource,
    currentCurrency,
    disableSessionLeftTimer,
    closeEventSource,
  } = usePaymentContext()
  const { params } = useSearchParams()
  const { token } = usePaymentTokenContext()
  const { getField } = useLanguage()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const confirmContractPost = () => {
    setIsSubmitting(true)

    apiService.contract
      .confirmContract(contractId, {
        headers: {
          [Headers.Token]: token,
        },
      })
      .catch((err: AxiosError) => {
        setIsSubmitting(false)
        console.error(err)

        const status = err?.response?.status

        if (status && status >= 400 && status < 500) {
          window.location.href = PAYMENT_FAILED_PAGE
        }
      })
  }

  const handleContractInvalidEvent = useCallback(() => {
    disableSessionLeftTimer()
    setIsSubmitting(false)
  }, [])

  const handleCompletedEvent = useCallback(() => {
    disableSessionLeftTimer()
    navigate({
      pathname: PAYMENT_SUCCESS_PAGE,
      search: new URLSearchParams(params).toString(),
    })
  }, [])

  const handleFailedEvent = useCallback(() => {
    disableSessionLeftTimer()
    setIsSubmitting(false)
    navigate({
      pathname: PAYMENT_FAILED_PAGE,
      search: new URLSearchParams(params).toString(),
    })
  }, [])

  const handlePaymentConfirmationRequired = useCallback<
    Exclude<
      Parameters<
        typeof useUserDataSSEEvents
      >['0']['contract_confirmation_required'],
      undefined
    >
  >(
    (event, func) =>
      defaultPaymentConfirmationRequiredHandler({
        closeConnection: () => {
          closeEventSource()
        },
        qr: (code: string) => {
          navigate(PAYMENT_CONFIRM_QR_PAGE, { state: { code } })
        },
        iframeContainerSelector: '#iframe-wrapper',
      })(event, func),
    [closeEventSource],
  )

  useUserDataSSEEvents({
    withLogs: true,
    eventSource,
    completed: handleCompletedEvent,
    contract_invalid: handleContractInvalidEvent,
    consumerFailed: handleFailedEvent,
    payment_confirmation_required: handlePaymentConfirmationRequired,
  })

  const dd = String(new Date().getDate()).padStart(2, '0')
  const mm = String(new Date().getMonth() + 1).padStart(2, '0')
  const yy = new Date().getFullYear()

  const currentDate = formatLocalizationString(
    getField('payment_confirm_date_name'),
    {
      mm: mm,
      dd: dd,
      yyyy: yy,
    },
  )

  const iconMatcher: Record<string, ReactNode> = useMemo(() => {
    return {
      mir: <MirIcon />,
      visa: <VisaIcon />,
      mastercard: <MastercardIcon />,
    }
  }, [])

  const { isScreenSm } = useMediaContext()

  return (
    <div className={styles['payment']}>
      <div id="iframe-wrapper" className={styles['iframe-wrapper']} />
      <div className={styles['payment-body']}>
        <div className={styles['header']}>
          <Typography
            tag="span"
            fontSize={isScreenSm ? '20' : '35'}
            lineHeight="45"
            letterSpacing="micro"
            color="region-surface-on-surface-contrast-highest"
          >
            {getField('payment_confirm_title')}
          </Typography>
        </div>
        <div className={styles['body']}>
          <div className={styles['top-section-body']}>
            {process.env.REACT_APP_TYPE === 'pci_dss' ? (
              <div className={styles['payment-info']}>
                <Typography
                  tag="span"
                  fontSize="15"
                  lineHeight="25"
                  letterSpacing="small"
                  color="region-surface-on-surface-contrast-highest"
                >
                  {getField('payment_confirm_card_name')}
                </Typography>
                <div className={styles['card-info']}>
                  <div className={styles['card-number-info']}>
                    <EllipseWhiteIcon className={styles['ellipse-icon']} />
                    <Typography
                      tag="span"
                      fontSize="13"
                      lineHeight="20"
                      color="region-surface-on-surface-contrast-highest"
                    >
                      {cardForPayment?.last_4}
                    </Typography>
                  </div>
                  {cardForPayment?.system &&
                    iconMatcher[cardForPayment?.system]}
                </div>
              </div>
            ) : null}
            <div className={styles['payment-info']}>
              <Typography
                tag="span"
                fontSize="15"
                lineHeight="25"
                letterSpacing="small"
                color="region-surface-on-surface-contrast-highest"
              >
                {getField('payment_confirm_date_payment_name')}
              </Typography>
              <Typography
                tag="span"
                fontSize="13"
                lineHeight="20"
                letterSpacing="small"
                color="region-surface-on-surface-contrast-highest"
              >
                {currentDate}
              </Typography>
            </div>
          </div>

          <div className={styles['middle-section-body']}>
            <div className={styles['payment-info']}>
              <Typography
                tag="span"
                fontSize="15"
                lineHeight="25"
                letterSpacing="small"
                color="region-surface-on-surface-contrast-highest"
              >
                {getField('payment_confirm_pay_name')}
              </Typography>
              <Typography
                tag="span"
                fontSize="20"
                lineHeight="25"
                letterSpacing="mini"
                color="region-surface-on-surface-contrast-highest"
              >
                {formatLocalizationString(
                  getField('payment_confirm_pay_amount'),
                  {
                    currency:
                      CurrencyMatcher[sessionData?.amount_currency ?? ''],
                    amount: amountParser(
                      sessionData?.amount_value,
                      currentCurrency,
                    ),
                  },
                )}
              </Typography>
            </div>
          </div>
          <Button
            onClick={confirmContractPost}
            type="submit"
            variant="fill"
            size="standart"
            disabled={isSubmitting}
            className={styles['button-confirm']}
          >
            {getField('cards_control_confirm_button')}
          </Button>
        </div>
      </div>
    </div>
  )
}
