// Оплата
export const PAYMENT_PATH = '/payment'
enum PaymentPages {
  Service = 'service',
  Method = 'method',
  Form = 'form',
  Success = 'success',
  Failed = 'failed',
  Confirm = 'confirm',
  ConfirmQr = 'confirm/qr',
  Process = 'process',
  Interrupt = 'interrupt',
  Pending = 'pending',
}

// TODO Управление (пока не используется)
export const MANAGEMENT_PATH = '/management'
enum ManagementPages {
  Card = 'card',
}

// Для разработчиков и тестеровщиков
const TOKEN_PATH = PAYMENT_PATH + '/token'

// Пути
export const PAYMENT_SERVICE_PAGE = `${PAYMENT_PATH}/${PaymentPages.Service}`
export const PAYMENT_METHOD_PAGE = `${PAYMENT_PATH}/${PaymentPages.Method}`
export const PAYMENT_FORM_PAGE = `${PAYMENT_PATH}/${PaymentPages.Form}`
export const PAYMENT_SUCCESS_PAGE = `${PAYMENT_PATH}/${PaymentPages.Success}`
export const PAYMENT_FAILED_PAGE = `${PAYMENT_PATH}/${PaymentPages.Failed}`
export const PAYMENT_CONFIRM_PAGE = `${PAYMENT_PATH}/${PaymentPages.Confirm}`
export const PAYMENT_CONFIRM_QR_PAGE = `${PAYMENT_PATH}/${PaymentPages.ConfirmQr}`
export const PAYMENT_PROCESS_PAGE = `${PAYMENT_PATH}/${PaymentPages.Process}`
export const PAYMENT_INTERRUPT_PAGE = `${PAYMENT_PATH}/${PaymentPages.Interrupt}`
export const PAYMENT_PENDING_PAGE = `${PAYMENT_PATH}/${PaymentPages.Pending}`

export const MANAGEMENT_CARD_PAGE = `${MANAGEMENT_PATH}/${ManagementPages.Card}`

export const TOKEN_PAGE = TOKEN_PATH
