import { createElement, FC, useEffect, useRef } from 'react'
import { LoaderProps } from './loader.types'
import { ReactComponent as SpinnerBigYellowIcon } from '../../icons/spinner-large-yellow.svg'
import { ReactComponent as SpinnerBigWhiteIcon } from '../../icons/spinner-large-white.svg'
import { ReactComponent as SpinnerSmallYellowIcon } from '../../icons/spinner-small-yellow.svg'
import { ReactComponent as SpinnerSmallWhiteIcon } from '../../icons/spinner-small-white.svg'
import clsx from 'clsx'
import styles from './loader.module.scss'
import { useTheme } from '@/shared/contexts/theme-context'

const spinners = {
  big: {
    yellow: SpinnerBigYellowIcon,
    white: SpinnerBigWhiteIcon,
  },
  small: {
    yellow: SpinnerSmallYellowIcon,
    white: SpinnerSmallWhiteIcon,
  },
}

const tokens = {
  yellow: {
    main: '--theme-gradient-green-stop-0',
  },
  white: {
    main: '--theme-grey-900',
  },
}

export const Loader: FC<LoaderProps> = ({
  size = 'big',
  color = 'yellow',
  className,
  ...props
}) => {
  const svgRef = useRef<SVGSVGElement>(null)
  const { theme } = useTheme()

  const loaderClasses = clsx(styles['loader-container'], className)

  useEffect(() => {
    const styles = window.getComputedStyle(document.body)

    const elems = {
      Path: svgRef.current?.querySelector('#Path') as
        | SVGPathElement
        | undefined,
      stops: svgRef.current?.querySelectorAll('stop'),
    }

    if (elems.Path) {
      elems.Path.style.fill = styles.getPropertyValue(tokens[color].main)
    }
    elems.stops?.forEach((stop) => {
      stop.style.stopColor = styles.getPropertyValue(tokens[color].main)
    })
  }, [svgRef.current, color, theme])

  return (
    <div className={loaderClasses} {...props}>
      <div className={styles['loader']}>
        {createElement(spinners[size][color], {
          ref: svgRef,
        })}
      </div>
    </div>
  )
}
