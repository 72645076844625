import { FC, createElement } from 'react'
import { TypographyProps } from './typography.types'

export const Typography: FC<TypographyProps> = ({
  tag = 'p',
  fontSize,
  lineHeight,
  letterSpacing,
  color,
  children,
  ...props
}) => {
  return createElement(tag, {
    style: {
      fontSize: fontSize ? `var(--font-size-${fontSize})` : undefined,
      lineHeight: lineHeight ? `var(--line-height-${lineHeight})` : undefined,
      letterSpacing: letterSpacing
        ? `var(--letter-spacing-${letterSpacing})`
        : undefined,
      color: color ? `var(--${color})` : undefined,
    },
    ...props,
    children,
  })
}
