import type { FC } from 'react'
import type {
  CardsTokenContextProviderProps,
  ITokenContext,
  PaymentTokenContextProviderProps,
  TokenContextProviderProps,
} from './token-context.type'

import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react'

import { useSearchParams } from '@/shared/contexts/search-params-context'
import { TokenResponse, PaymentMethodToken, apiService } from '@/shared/api'
import { SearchParams } from '@/shared/constants/search-params'
import { Headers } from '@/shared/constants/headers'
import { AxiosError } from 'axios'
import { PAYMENT_FAILED_PAGE } from '@/shared/constants/routes'

export const tokenContextDefaultValue: ITokenContext<any> = {
  data: null,
  token: null,
  loading: true,
  error: null,
  setToken() {},
}

export function TokenContextProvider<T>({
  children,
  decodeToken,
  context,
}: TokenContextProviderProps<T>): ReturnType<FC> {
  const { params, setParams } = useSearchParams()

  const abortController = useRef<null | AbortController>(null)

  const [token, setToken] = useState<string | null>(
    tokenContextDefaultValue.token,
  )
  const [requestInfo, setRequestInfo] = useState<
    Omit<ITokenContext<T>, 'setToken' | 'token'>
  >({
    loading: tokenContextDefaultValue.loading,
    error: tokenContextDefaultValue.error,
    data: tokenContextDefaultValue.data,
  })

  const updateToken = useCallback((token: string | null) => {
    setParams((prev) => ({ ...prev, [SearchParams.Token]: token }))
  }, [])

  useEffect(() => {
    if (token !== params[SearchParams.Token]) {
      setToken(
        !!params[SearchParams.Token]?.trim()
          ? params[SearchParams.Token]
          : null,
      )
    }
  }, [params[SearchParams.Token]])

  //   TODO былобы неплохо завернуть это в дебаунс
  useEffect(() => {
    if (!!token?.trim()) {
      setRequestInfo({ data: null, error: null, loading: true })

      //   Отменяем предыдущий запрос, если он выполняется
      abortController.current?.abort()
      abortController.current = new AbortController()

      decodeToken({
        headers: {
          [Headers.Token]: token,
        },
        signal: abortController.current.signal,
      })
        .then(({ data }) => {
          setRequestInfo((prev) => ({ ...prev, data }))
        })
        .catch((err: AxiosError) => {
          console.error(err)
          setRequestInfo((prev) => ({ ...prev, error: err }))

          const status = err?.response?.status

          if (status && status >= 400 && status < 500) {
            window.location.href = PAYMENT_FAILED_PAGE
          }
        })
        .finally(() => {
          setRequestInfo((prev) => ({ ...prev, loading: false }))
        })
    } else {
      setRequestInfo({ data: null, error: null, loading: false })
    }
  }, [token])

  return (
    <context.Provider
      value={{
        token: token,
        loading: requestInfo.loading,
        error: requestInfo.error,
        data: requestInfo.data,
        setToken: updateToken,
      }}
    >
      {children}
    </context.Provider>
  )
}

// Контексты для получения данных из токена
export const PaymentTokenContext = createContext<ITokenContext<TokenResponse>>(
  tokenContextDefaultValue,
)
export const CardsTokenContext = createContext<
  ITokenContext<PaymentMethodToken>
>(tokenContextDefaultValue)

export const PaymentTokenContextProvider: FC<
  PaymentTokenContextProviderProps
> = (props) => {
  return (
    <TokenContextProvider
      context={PaymentTokenContext}
      decodeToken={apiService.paymentSession.decodeToken}
      {...props}
    />
  )
}

export const CardsTokenContextProvider: FC<CardsTokenContextProviderProps> = (
  props,
) => {
  return (
    <TokenContextProvider
      context={CardsTokenContext}
      decodeToken={apiService.method.decodeMethodToken}
      {...props}
    />
  )
}

export const usePaymentTokenContext = () => useContext(PaymentTokenContext)
export const useCardsTokenContext = () => useContext(CardsTokenContext)
