import type { FC } from 'react'
import type { FormValues } from '@/shared/interfaces/interfaces'

import { useCallback, useState, useRef, useEffect } from 'react'
import { useForm, Controller, SubmitHandler, useWatch } from 'react-hook-form'

import { Button } from '@/shared/components/button'
import { getFieldValue } from '@/shared/utils/fields-data'
import { InputType } from '@/entities/input-wrappers'
import { usePaymentContext } from '@/shared/contexts/payment-context'
import { useUserDataSSEEvents } from '@/shared/hooks/use-user-data-sse-events'
import { useSortedFields } from '@/shared/hooks/use-sorted-fields'
import { CurrencyMatcher } from '@/shared/constants/currency-matcher'
import { formatLocalizationString } from '@/shared/utils'
import { apiService, type ContractRequestWithExample } from '@/shared/api'
import { useLanguage } from '@/shared/contexts/language-context'
import { TypedInput } from '@/entities/input-wrappers'
import { MethodForm } from '@/shared/components/method-from'
import { usePaymentTokenContext } from '@/shared/contexts/token-context'
import { Headers } from '@/shared/constants/headers'
import { getCardType } from '@/shared/hooks/use-input-mask'
import { defaultPaymentConfirmationRequiredHandler } from '@/shared/utils/default-sse-handlers'

import { validate } from './validations'
import { useFormDisabled } from './use-form-disabled'

import styles from './user-data.module.scss'
import { amountParser } from '@/shared/utils/amount-parser'
import { useUserSSEHandlers } from './use-user-sse-handlers'
import { UserDataProps } from './user-data.props'
import clsx from 'clsx'
import { PAYMENT_FORM_PAGE_DATA_ID } from '@/shared/constants/test/data-testid'
import { AxiosError } from 'axios'
import { PAYMENT_FAILED_PAGE } from '@/shared/constants/routes'

export const UserData: FC<UserDataProps> = ({ isOpen }) => {
  const { token } = usePaymentTokenContext()
  const {
    eventSource,
    sessionData,
    method,
    savedCard,
    setCardForPayment,
    selectedFields,
    currentCurrency,
    selectedFieldsLoading,
    closeEventSource,
  } = usePaymentContext()
  const { getField } = useLanguage()
  const { sortedFields, focusOnNextField } = useSortedFields(selectedFields)

  const formRef = useRef<HTMLDivElement | null>(null)

  const [savePaymentMethod, setSavePaymentMethod] = useState(false)
  const [savedCardSystem, setSavedCardSystem] = useState<string | null>(null)
  const [errorStatus, setErrorStatus] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [fieldErrors, setFieldErrors] = useState<
    Record<string, { message: string | boolean } | undefined>
  >({})

  const {
    control,
    handleSubmit,
    trigger,
    formState: { touchedFields },
    setValue,
  } = useForm<FormValues>({
    // Для решения проблемы с оплатой сохраненной карты возвращаем валидации, что у нас никогда нет ошибок
    // Кнопка блокируется в хуке ниже
    resolver: (values) => ({
      errors: {},
      values,
    }),
  })

  // Для отслеживания значений при валидации
  const values = useWatch({ control })

  useEffect(() => {
    if (savedCard) {
      setValue('card_csc', '')
      setSavedCardSystem(getCardType(savedCard.data.card_number_first6))
      setFieldErrors({
        ...validate(values, touchedFields, getField, !!savedCard).errors,
        card_csc: { message: true },
      })
    } else {
      setSavedCardSystem(null)
    }
  }, [savedCard])

  const isFormDisabled = useFormDisabled({
    sortedFields,
    values,
    touched: touchedFields,
    errors: fieldErrors,
    checkFields: savedCard ? ['card_csc'] : null,
  })

  const {
    handleCompletedEvent,
    handleContractInvalidEvent,
    handleFailedEvent,
    handleContractConfirmationRequiredEvent,
  } = useUserSSEHandlers()

  const handlePaymentConfirmationRequired = useCallback<
    Exclude<
      Parameters<
        typeof useUserDataSSEEvents
      >['0']['contract_confirmation_required'],
      undefined
    >
  >(
    (event, func) =>
      defaultPaymentConfirmationRequiredHandler({
        closeConnection: () => {
          closeEventSource()
        },
      })(event, func),
    [closeEventSource],
  )

  useUserDataSSEEvents({
    withLogs: true,
    eventSource,
    completed: handleCompletedEvent,
    contract_confirmation_required: handleContractConfirmationRequiredEvent,
    contract_invalid: handleContractInvalidEvent,
    payment_confirmation_required: handlePaymentConfirmationRequired,
    consumerFailed: handleFailedEvent,
    handlerFailed: handleContractInvalidEvent,
  })

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    (data) => {
      if (sessionData && token) {
        setIsSubmitting(true)

        const savedCardData = savedCard?.data

        // TODO есть ли верроятность, что этот проект будет существовать в 2100+ году?🙃

        const expiryYear = data?.card_expiry_year
          ? `20${data?.card_expiry_year}`
          : undefined
        const expiryMonth = data.card_expiry_month
          ? `${data?.card_expiry_month?.length === 1 ? '0' : ''}${
              data.card_expiry_month
            }`
          : undefined

        const formData: ContractRequestWithExample = {
          ...sessionData,
          save_payment_method: savePaymentMethod,
          user_payment_method_id: savedCard ? savedCard?.id : null,
          user_payment_method_data: {
            card_cardholder: savedCardData
              ? savedCardData?.card_cardholder.toUpperCase()
              : data?.card_cardholder?.toUpperCase(),
            card_csc: data.card_csc,
            card_expiry_month: savedCardData
              ? savedCardData?.card_expiry_month
              : expiryMonth,
            card_expiry_year: savedCardData
              ? savedCardData?.card_expiry_year
              : expiryYear,
            card_number: savedCardData
              ? null
              : data?.card_number?.replace(/\s/g, ''),
            email_address: data.email_address,
            neteller_id: data.neteller_id,
            phone: data.phone,
            type: method?.name,
          },
          customer: {
            screen_res_w: window.screen.width,
            screen_res_h: window.screen.height,
          },
        }

        setCardForPayment({
          system:
            getCardType(
              savedCard
                ? savedCard?.data.card_number_first6 ?? ''
                : data.card_number ?? '',
            ) ?? undefined,
          last_4: savedCard
            ? savedCard?.data?.card_number_last4
            : data?.card_number?.replace(/\s/g, '').slice(-4),
        })

        apiService.contract
          .createContract(formData, {
            headers: {
              [Headers.Token]: token,
            },
          })
          .catch((err: AxiosError) => {
            console.error(err)

            setIsSubmitting(false)
            setErrorStatus(true)

            const status = err?.response?.status

            if (status && status >= 400 && status < 500) {
              window.location.href = PAYMENT_FAILED_PAGE
            }
          })
      }
    },
    [sessionData, token, savePaymentMethod, savedCard],
  )

  const handleBlur = useCallback(
    (fieldName: keyof FormValues, value: FormValues[keyof FormValues]) => {
      trigger(fieldName)
      if (fieldName === 'card_expiry_month') {
        if (value?.length === 1) {
          setValue(fieldName, value.padStart(2, '0'), { shouldTouch: true })
        }
      }
    },
    [trigger],
  )

  useEffect(() => {
    if (
      !selectedFieldsLoading &&
      selectedFields.length === 0 &&
      !isSubmitting
    ) {
      handleSubmit(onSubmit)()
    }
  }, [selectedFieldsLoading, selectedFields])

  return sortedFields.length ? (
    <div
      ref={formRef}
      className={clsx(styles['form-wrapper'], { [styles['hidden']]: !isOpen })}
      data-testid={PAYMENT_FORM_PAGE_DATA_ID.DATA_CONTAINER}
    >
      <MethodForm
        onSubmit={handleSubmit(onSubmit)}
        submitDisabled={isSubmitting || isFormDisabled}
        displaySavePaymentMethod={!savedCard}
        checkboxDisabled={isSubmitting}
        checkboxText={getField('payment_data_save_data_checkbox_label')}
        buttonPayText={formatLocalizationString(
          getField('payment_data_buy_button'),
          {
            currency: CurrencyMatcher[sessionData?.amount_currency ?? ''],
            amount: amountParser(sessionData?.amount_value, currentCurrency),
          },
        )}
        sortedFields={sortedFields}
        savePaymentMethod={savePaymentMethod}
        onSavePaymentMethod={() => setSavePaymentMethod((prev) => !prev)}
        inputRender={(column_field) => (
          <Controller
            key={column_field.tbaIdx}
            name={column_field.name as keyof FormValues}
            control={control}
            defaultValue=""
            rules={{
              required: getField('payment_data_required_field_error'),
            }}
            render={({ field }) => {
              return (
                <div
                  className={styles['input-wrapper']}
                  style={{
                    flex: column_field.field_width ?? 1,
                  }}
                >
                  <TypedInput
                    data-iserror={!!fieldErrors[field.name]?.message}
                    data-testid={PAYMENT_FORM_PAGE_DATA_ID.DATA_INPUT}
                    key={field.name}
                    {...field}
                    name={field.name}
                    tooltipText={getField(`payment_data_${field.name}_tooltip`)}
                    tabIndex={column_field.tbaIdx}
                    type={field.name as InputType}
                    placeholder={
                      process.env.REACT_APP_TYPE === 'pci_dss'
                        ? getField(`payment_data_${field.name}_name`)
                        : getField(
                            `payment_data_metabilling_${field.name.toLowerCase()}`,
                          )
                    }
                    disabled={
                      // TODO костыль
                      savedCard
                        ? column_field.name !== 'card_csc' && true
                        : false
                    }
                    value={getFieldValue(field, savedCard)}
                    className={styles['payment-input']}
                    onBlur={() => {
                      if (errorStatus) setErrorStatus(false)

                      setFieldErrors(
                        validate(values, touchedFields, getField, !!savedCard)
                          .errors,
                      )

                      handleBlur(
                        column_field.name as keyof FormValues,
                        field.value,
                      )
                    }}
                    system={
                      savedCard && column_field.name === 'card_number'
                        ? savedCardSystem
                        : null
                    }
                    onChange={({ target }) => {
                      setFieldErrors({
                        ...fieldErrors,
                        [target.name]: { message: false },
                      })
                      setValue(field.name, (target as HTMLInputElement).value, {
                        shouldTouch: true,
                      })
                    }}
                    onPaste={(e) => {
                      setValue(
                        field.name,
                        e.clipboardData.getData('masked-text') ??
                          e.clipboardData.getData('text'),
                        { shouldTouch: true },
                      )
                    }}
                    // TODO избавиться от as
                    description={fieldErrors[field.name]?.message as string}
                    state={
                      fieldErrors[field.name]?.message ? 'error' : 'default'
                    }
                    descriptionIgnoreParentWidth
                    onNext={() =>
                      formRef.current &&
                      focusOnNextField(field.name, formRef.current)
                    }
                  />
                </div>
              )
            }}
          />
        )}
      />
      {errorStatus && (
        <div className={styles['error-button-wrapper']}>
          <Button
            onClick={() => setErrorStatus(false)}
            type="submit"
            size="small"
            variant="fill"
            danger={true}
            className={styles['error-button']}
          >
            {getField('payment_data_card_number_error')}
          </Button>
        </div>
      )}
    </div>
  ) : null
}
