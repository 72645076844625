import { FC } from 'react'
import { NewCardProps } from './new-card.types'
import clsx from 'clsx'
import { Typography } from '@/shared/components/typography'
import styles from './new-card.module.scss'

export const NewCard: FC<NewCardProps> = ({
  onClick = () => {},
  text,
  isActive = false,
  className,
  ...props
}) => {
  const containerClass = clsx(
    styles['container'],
    isActive && styles['container_active'],
    className,
  )
  const contentClass = clsx(
    styles['content'],
    isActive && styles['content_active'],
  )

  return (
    <div
      className={containerClass}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => onClick(e)}
      data-testid={props.testId}
      {...props}
    >
      <div className={contentClass}>
        <div>{props.icon}</div>
        <Typography>{text}</Typography>
      </div>
    </div>
  )
}
