import { Fragment, type FC } from 'react'
import type { MethodFormProps } from './method-form.type'

import { Button } from '../button'
import { Checkbox } from '../checkbox'

import styles from './method-form.module.scss'

export const MethodForm: FC<MethodFormProps> = ({
  submitDisabled = false,
  checkboxDisabled = false,
  checkboxText = '',
  buttonPayText = '',
  onSubmit,
  displaySavePaymentMethod = false,
  savePaymentMethod,
  onSavePaymentMethod,
  sortedFields = [],
  inputRender,
}) => {
  return (
    <form
      className={styles['payment-body__form']}
      onSubmit={onSubmit}
      autoComplete="off"
    >
      <div className={styles['fields-wrapper']}>
        {sortedFields.length ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {sortedFields.map((row, idx) => (
              <div
                key={idx}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  gap: 15,
                }}
              >
                {row?.length ? (
                  row.map((field) => (
                    <Fragment key={field.id}>{inputRender(field)}</Fragment>
                  ))
                ) : (
                  <div />
                )}
              </div>
            ))}
          </div>
        ) : null}
        {displaySavePaymentMethod &&
          sortedFields.length !== 0 &&
          process.env.REACT_APP_TYPE !== 'mb' && (
            <Checkbox
              label={checkboxText}
              checked={savePaymentMethod}
              disabled={checkboxDisabled}
              onChange={onSavePaymentMethod}
              className={styles['payment-checkbox']}
            />
          )}
      </div>
      <Button
        type="submit"
        size="standart"
        variant="fill"
        className={styles['payment-button']}
        disabled={submitDisabled}
      >
        {buttonPayText}
      </Button>
    </form>
  )
}
