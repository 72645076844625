import type { FC } from 'react'

import { useEffect } from 'react'
import { Outlet, useMatch } from 'react-router'

import { useLanguage } from '@/shared/contexts/language-context'
import {
  PaymentContextProvider,
  usePaymentContext,
} from '@/shared/contexts/payment-context'
import {
  PaymentTokenContextProvider,
  usePaymentTokenContext,
} from '@/shared/contexts/token-context/token-context'
import { LocalStorageKeys } from '@/shared/constants/local-storage'
import { LazyLoader } from '@/entities/lazy-loader/lazy-loader'
import { TOKEN_PAGE } from '@/shared/constants/routes'
import { useTheme } from '@/shared/contexts/theme-context'

const PaymentRoutes: FC = () => {
  const { loading: languageLoading, changeLanguage } = useLanguage()
  const { loading: tokenLoading, data } = usePaymentTokenContext()
  const {
    loading: paymentDataLoading,
    savedCardsLoading,
    currenciesLoading,
  } = usePaymentContext()
  const { setTheme } = useTheme()

  const tokenPageMatch = useMatch(TOKEN_PAGE)

  const loading =
    tokenLoading ||
    languageLoading ||
    paymentDataLoading ||
    savedCardsLoading ||
    currenciesLoading

  useEffect(() => {
    if (data) {
      changeLanguage(
        data.language_code ?? process.env.REACT_APP_DEFAULT_LANGUAGE,
      ).then(({ language_code }) => {
        localStorage.setItem(LocalStorageKeys.Language, language_code)
        return
      })
      if (data.ui_theme) {
        setTheme(data.ui_theme)
      }
    }
  }, [data])

  return process.env.REACT_APP_SETTINGS_PAGE && !!tokenPageMatch ? (
    <Outlet />
  ) : loading ? (
    <LazyLoader />
  ) : (
    <Outlet />
  )
}

const PaymentRoutesWrapper: FC = () => {
  return (
    <PaymentTokenContextProvider>
      <PaymentContextProvider>
        <PaymentRoutes />
      </PaymentContextProvider>
    </PaymentTokenContextProvider>
  )
}

export { PaymentRoutesWrapper as PaymentRoutes }
