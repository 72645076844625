export enum ParentEvents {
  FullReload = 'full_reload',
  SuccessPageOpen = 'success_page_open',
  SuccessBackButtonClick = 'success_back_button_click',
  FailedBackButtonClick = 'failed_back_button_click',

  InterruptPageOpen = 'interrupt_page_open',
  InterruptRetryButtonClick = 'interrupt_retry_button_click',

  PendingPageOpen = 'pending_page_open',
  PendingBackButtonClick = 'pending_back_button_click',

  FailedPageOpen = 'failed_page_open',
  FailedRetryButtonClick = 'failed_retry_button_click',
  ShowIframeFullScreen = 'show_iframe_full_screen',
  ShowIframeSmallScreen = 'show_iframe_small_screen',
  MethodsPageOpen = 'methods_page_open',
  SetHeight = 'set_height',
}
