import type { FC } from 'react'
import type {
  LanguageContextProviderProps,
  ILanguageContext,
} from './language-context.types'
import { apiService, type LocalizationDataStored } from '@/shared/api'

import { createContext, useContext, useState, useCallback, useRef } from 'react'
import { AxiosError } from 'axios'

export const languageContextDefaultValue: ILanguageContext = {
  code: '',
  fields: null,
  loading: true,
  error: null,
  changeLanguage: () => new Promise((res, rej) => rej()),
  getField: () => '',
}

// Контекст для языка из токена
export const LanguageContext = createContext<ILanguageContext>(
  languageContextDefaultValue,
)

export const LanguageContextProvider: FC<LanguageContextProviderProps> = ({
  children,
}) => {
  const abortController = useRef<AbortController | null>(null)

  const [requestInfo, setRequestInfo] = useState<{
    loading: boolean
    error: null | any
  }>({
    loading: languageContextDefaultValue.loading,
    error: languageContextDefaultValue.error,
  })
  const [data, setData] = useState<LocalizationDataStored | null>(null)

  const changeLanguage = useCallback((language: string) => {
    abortController.current?.abort()
    abortController.current = new AbortController()

    setRequestInfo((prev) => ({ ...prev, loading: true }))

    return new Promise<LocalizationDataStored>((res, rej) => {
      apiService.parametrization
        .getLocalization(language, {
          signal: abortController.current?.signal,
        })
        .then(({ data: result }) => {
          setData(result)

          res(result)
        })
        .catch((err: AxiosError) => {
          console.error(err?.code)
          setRequestInfo((prev) => ({ ...prev, error: err }))
          rej(err)
        })
        .finally(() => {
          setRequestInfo((prev) => ({ ...prev, loading: false }))
        })
    })
  }, [])

  const getField = useCallback(
    (key: string) => {
      if (data?.data) {
        return (data.data as Record<string, string>)[key] ?? ''
      } else {
        return ''
      }
    },
    [data],
  )

  return (
    <LanguageContext.Provider
      value={{
        loading: requestInfo.loading,
        error: requestInfo.error,
        code: data?.language_code ?? '',
        fields: data?.data ?? {},
        changeLanguage,
        getField,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => useContext(LanguageContext)
