import { SearchParamsProvider } from '@/shared/contexts/search-params-context'
import { LanguageContextProvider } from '@/shared/contexts/language-context'
import 'tippy.js/themes/light.css'
import 'tippy.js/dist/tippy.css'

import { Routes } from './routes'

import styles from './app.module.scss'

import { MediaContextProvider } from '@/shared/contexts/media-context'
import { ThemeProvider } from '@/shared/contexts/theme-context'

export const App = () => {
  return (
    <div className={styles.App}>
      <MediaContextProvider>
        <SearchParamsProvider>
          <LanguageContextProvider>
            <ThemeProvider>
              <Routes />
            </ThemeProvider>
          </LanguageContextProvider>
        </SearchParamsProvider>
      </MediaContextProvider>
    </div>
  )
}
