import type { FC } from 'react'
import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import {
  IManagementContext,
  ManagementCardsContextProviderProps,
} from './management-cards-context.type'
import { UserPaymentMethodPublic } from '@/shared/api'
import { apiService } from '@/shared/api'
import { Headers } from '@/shared/constants/headers'
import { useCardsTokenContext } from '@/shared/contexts/token-context'

export const managementCardsContextDefaultValue: IManagementContext = {
  savedCards: null,
  loading: true,
  getCards: () => {},
}

export const ManagementCardsContext = createContext<IManagementContext>(
  managementCardsContextDefaultValue,
)

export const ManagementCardsContextProvider: FC<
  ManagementCardsContextProviderProps
> = ({ children }) => {
  const { token } = useCardsTokenContext()
  const [savedCards, setSavedCards] = useState<
    UserPaymentMethodPublic[] | null
  >(null)

  const [dataInfo, setDataInfo] = useState<{ loading: boolean }>({
    loading: managementCardsContextDefaultValue.loading,
  })

  const getCards = useCallback(() => {
    if (token) {
      setDataInfo((prev) => ({ ...prev, loading: true }))
      apiService.method
        .getMethodsByUser({
          headers: {
            [Headers.Token]: token,
          },
        })
        .then(({ data }) => {
          setSavedCards(data)
        })
        .catch((e) => {
          console.error(e)
          setSavedCards([])
        })
        .finally(() => {
          setDataInfo((prev) => ({ ...prev, loading: false }))
        })
    }
  }, [token])

  useEffect(() => {
    if (token) {
      getCards()
    }
  }, [token])

  return (
    <ManagementCardsContext.Provider
      value={{
        savedCards: savedCards,
        loading: dataInfo.loading,
        getCards,
      }}
    >
      {children}
    </ManagementCardsContext.Provider>
  )
}
export const useManagementContext = () => useContext(ManagementCardsContext)
