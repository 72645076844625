import type { FC } from 'react'

import { useEffect } from 'react'
import { Typography } from '@/shared/components/typography'
import { useLanguage } from '@/shared/contexts/language-context'
import { ReactComponent as PaymentPendingIcon } from '@/shared/icons/payment-pending-icon.svg'
import { useMediaContext } from '@/shared/contexts/media-context/media-context'
import { postEventToParent } from '@/shared/utils/post-event-po-parent'
import { ParentEvents } from '@/shared/constants/parent-events'

import styles from './payment-result.module.scss'
import { Button } from '@/shared/components/button'
import clsx from 'clsx'

export const PaymentPending: FC = () => {
  const { getField } = useLanguage()
  const { isScreenSm } = useMediaContext()

  const onBack = () => {
    postEventToParent({ event: ParentEvents.PendingBackButtonClick })
    postEventToParent({ event: ParentEvents.ShowIframeSmallScreen })
  }

  useEffect(() => {
    postEventToParent({ event: ParentEvents.PendingPageOpen })
  }, [])

  return (
    <div className={styles.payment}>
      <div
        className={clsx(
          styles['payment-body'],
          isScreenSm && styles['payment-body_mobile'],
        )}
      >
        <div className={styles['top-slot']}>
          <PaymentPendingIcon className={styles['pending-icon']} />
          <Typography
            tag="span"
            fontSize={isScreenSm ? '20' : '35'}
            letterSpacing="micro"
            color="region-surface-on-surface-contrast-highest"
          >
            {getField('payment_waiting_title')}
          </Typography>
        </div>
        <div
          className={clsx(styles['bottom-slot'], !isScreenSm && styles.pending)}
        >
          <Typography
            tag="span"
            fontSize="15"
            lineHeight="25"
            letterSpacing="small"
            color="region-surface-on-surface-contrast-default"
          >
            {getField('payment_waiting_text')}
          </Typography>
        </div>
        <Button
          variant="fill"
          className={clsx(
            styles['button-back'],
            isScreenSm && styles['button-back_mobile'],
          )}
          onClick={onBack}
        >
          {getField('payment_waiting_back_button')}
        </Button>
      </div>
    </div>
  )
}
