import { createElement, FC, ReactNode, SVGProps } from 'react'
import { SavedCardManagementProps } from './saved-card-management.types'
import { ReactComponent as MirWhiteIcon } from '@/shared/icons/mir-white-icon.svg'
import { ReactComponent as VisaWhiteIcon } from '@/shared/icons/visa-white-icon.svg'
import { ReactComponent as MastercardWhiteIcon } from '@/shared/icons/mastercard-white-icon.svg'
import { ReactComponent as EllipseWhiteIcon } from '@/shared/icons/ellipse-white-icon.svg'
import { ReactComponent as DeleteIcon } from '@/shared/icons/delete-icon.svg'

import clsx from 'clsx'
import styles from './saved-card-management.module.scss'
import { MANAGEMENT_CARDS_PAGE_DATA_ID } from '@/shared/constants/test/data-testid'

const iconMatcher: Record<string, FC<SVGProps<SVGSVGElement>>> = {
  mir: MirWhiteIcon,
  visa: VisaWhiteIcon,
  mastercard: MastercardWhiteIcon,
  '': () => <></>,
}

export const SavedCardManagement: FC<SavedCardManagementProps> = ({
  isActive = false,
  onConfirmDelete = () => {},
  onDelete = () => {},
  cardNumber,
  confirmText,
  tooltipText,
  paymentSystem,
  className,
  ...props
}) => {
  const containerClasses = clsx(styles['container'], className)

  return (
    <div className={containerClasses} {...props}>
      <div className={styles['content']}>
        <div className={styles['info-slot']}>
          <div className={styles['info-slot-left']}>
            <div className={styles['info-slot-left-system']}>
              {paymentSystem ? (
                createElement(iconMatcher[paymentSystem], {
                  className: clsx(
                    styles[`card-icon_${paymentSystem}`],
                    isActive && styles[`card-icon_${paymentSystem}_active`],
                  ),
                })
              ) : (
                <></>
              )}
            </div>
            <div className={styles['info-slot-left-card-info']}>
              <EllipseWhiteIcon
                className={clsx(
                  styles['ellipse-icon'],
                  isActive && styles['ellipse-icon_active'],
                )}
              />
              {cardNumber}
            </div>
          </div>
          <div>
            <div
              data-testid={MANAGEMENT_CARDS_PAGE_DATA_ID.DELETE_CARD_BTN}
              onClick={onDelete}
              className={styles['info-slot-right-delete']}
            >
              <DeleteIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
