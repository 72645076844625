import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { LocalStorageKeys } from '@/shared/constants/local-storage'
import { IThemeContext, ThemeProviderProps } from './theme-context.types'
import { DATA_THEME_MODE } from '@/shared/constants/data-attributes'

export const tokenContextDefaultValue: IThemeContext = {
  theme: '',
  setTheme: () => {},
}

const ThemeContext = createContext<IThemeContext>(tokenContextDefaultValue)

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<string>(() => {
    let theme = localStorage.getItem(LocalStorageKeys.Theme) ?? ''
    if (!theme) {
      theme = process.env.REACT_APP_DEFAULT_THEME ?? ''
    }

    return theme
  })

  const setThemeFn = useCallback((value: string) => {
    setTheme(value)
  }, [])

  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.Theme, theme)
    if (theme) {
      document.documentElement.setAttribute(DATA_THEME_MODE, theme)
    } else {
      document.documentElement.removeAttribute(DATA_THEME_MODE)
    }
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme: setThemeFn }}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)
