import type { FC } from 'react'
import type {
  IPaymentContext,
  PaymentContextProviderProps,
  UserPaymentMethodPublicWithData,
} from './payment-context.types'

import {
  useState,
  createContext,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react'
import { useNavigate } from 'react-router'

import { apiService, TokenResponse } from '@/shared/api'
import { useSSE } from '@/shared/hooks/use-sse'
import { SearchParams } from '@/shared/constants/search-params'
import { PAYMENT_FAILED_PAGE } from '@/shared/constants/routes'
import { usePaymentTokenContext } from '@/shared/contexts/token-context'
import { Headers } from '@/shared/constants/headers'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { matchPaymentPath } from '@/shared/utils/match-payment-path'

import { defaultValue } from './constants'
import { PaymentSessionTimerContextProvider } from '../payment-session-timer-context'
import { RequestDataInfo } from '@/shared/interfaces/interfaces'
import { AxiosError } from 'axios'

export const PaymentContext = createContext<IPaymentContext>(defaultValue)

export const PaymentContextProvider: FC<PaymentContextProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate()

  const { data: tokenData, token } = usePaymentTokenContext()
  const { params } = useSearchParams()

  const { eventSource, create, close } = useSSE()

  const [sessionData, setSessionData] = useState(tokenData)

  const [secondsToLeft, setSecondsToLeft] = useState<number | null>(null)
  const sessionLeftTimer = useRef<NodeJS.Timeout | null>(null)

  const [savedCards, setSavedCards] = useState(defaultValue.savedCards)
  const [savedCard, setSavedCard] = useState(defaultValue.savedCard)
  const [savedCardsLoading, setSavedCardsLoading] = useState(
    defaultValue.savedCardsLoading,
  )

  const [contractId, setContractId] = useState(defaultValue.contractId)

  const [method, selectMethod] = useState(defaultValue.method)
  const [fields, setFields] = useState(defaultValue.fields)
  const [selectedFields, setSelectedFields] = useState(
    defaultValue.selectedFields,
  )
  const [selectedFieldsLoading, setSelectedFieldsLoading] = useState(
    defaultValue.selectedFieldsLoading,
  )

  const [cardForPayment, setCardForPayment] = useState(
    defaultValue.cardForPayment,
  )

  const [dataInfo, setDataInfo] = useState<RequestDataInfo>({
    loading: defaultValue.loading,
  })

  const [currencies, setCurrencies] = useState(defaultValue.currencies)
  const [currenciesInfo, setCurrenciesInfo] = useState<RequestDataInfo>({
    loading: true,
  })
  const [currentCurrency, setCurrentCurrency] = useState(
    defaultValue.currentCurrency,
  )

  const disableSessionLeftTimer = useCallback(() => {
    if (sessionLeftTimer.current) {
      clearTimeout(sessionLeftTimer.current)
      sessionLeftTimer.current = null
    }
  }, [sessionLeftTimer])

  // Очищаем данные
  const resetPayment = useCallback(() => {
    disableSessionLeftTimer()
    setSecondsToLeft(null)
    setCardForPayment(null)

    close()
  }, [])

  // Создаем сессию и подключение
  const createSession = useCallback(
    (token: string, tokenData: TokenResponse) =>
      new Promise<NonNullable<typeof sessionData>>((res, rej) => {
        apiService.paymentSession
          .createPaymentSession({
            headers: { [Headers.Token]: token },
          })
          .then(({ data: { payment_session_ttl_in_seconds } }) => {
            // Закрытие сессии
            const resetSession = () => {
              resetPayment()
              // TODO нужна страница для истечения времени
              navigate({
                pathname: PAYMENT_FAILED_PAGE,
                search: new URLSearchParams(params).toString(),
              })
            }
            // Если сессия действительна, то устанавливаем таймер, если нет, то закрываем сессию
            if (payment_session_ttl_in_seconds > 0) {
              sessionLeftTimer.current = setTimeout(
                resetSession,
                payment_session_ttl_in_seconds * 1000,
              )

              // Запускаем таймер для пользоватeля
              setSecondsToLeft(payment_session_ttl_in_seconds)

              create(tokenData.order_id)

              res(tokenData)
            } else {
              resetSession()
              rej()
            }
          })
          .catch((err: AxiosError) => {
            const status = err?.response?.status

            if (status && status >= 400 && status < 500) {
              window.location.href = PAYMENT_FAILED_PAGE
            }
          })
      }),
    [],
  )

  // Если в параметрах выбран метод и система, то автоматически выполнится переход на нужную страницу
  const getSelectedMethod = useCallback(
    () =>
      new Promise<{
        payment_system_id: number | null
        payment_method_id: number | null
      }>((res) => {
        const service = params[SearchParams.Service]
          ? Number(params[SearchParams.Service])
          : null
        const method = params[SearchParams.Method]
          ? Number(params[SearchParams.Method])
          : null

        setSessionData((prev) =>
          prev
            ? {
                ...prev,
                payment_system_id:
                  prev.payment_system_id === null
                    ? service
                    : prev.payment_system_id,
                payment_method_id:
                  prev.payment_method_id === null
                    ? method
                    : prev.payment_method_id,
              }
            : prev,
        )

        if (!!service && !!method) {
          // Получем метод для установки его в стейт
          apiService.parametrization
            .getAllPaymentMethodsBySystemId(method)
            .then(({ data }) => {
              const selectedMethod = data.find((el) => el.id === method)
              if (selectedMethod) {
                selectMethod(selectedMethod)
              }
            })
            .finally(() => {
              res({
                payment_method_id: method,
                payment_system_id: service,
              })
            })
        } else {
          res({
            payment_method_id: sessionData?.payment_method_id ?? null,
            payment_system_id: sessionData?.payment_system_id ?? null,
          })
        }
      }),
    [params, sessionData],
  )

  const selectService = useCallback((service: number) => {
    setSessionData((prev) =>
      prev ? { ...prev, payment_system_id: service } : prev,
    )
  }, [])

  const updateSavedCards = useCallback(
    (token: string, tokenData: TokenResponse) => {
      return new Promise<TokenResponse>((res, rej) => {
        if (tokenData?.user_id) {
          setSavedCardsLoading(true)
          apiService.method
            .getMethodsByUser({
              headers: {
                [Headers.Token]: token,
              },
            })
            .then(({ data }) => {
              setSavedCards(data as UserPaymentMethodPublicWithData[])
              res(tokenData)
            })
            .catch((e) => {
              console.error(e)
              setSavedCards([])
              rej(e)
            })
            .finally(() => setSavedCardsLoading(false))
        } else {
          setSavedCards([])
          res(tokenData)
        }
      })
    },
    [],
  )

  const getCurrencies = useCallback((tokenData: TokenResponse) => {
    return new Promise((res, rej) => {
      if (tokenData?.user_id) {
        setCurrenciesInfo((prev) => ({ ...prev, loading: true }))
        setCurrentCurrency(null)
        apiService.parametrization
          .getSupportedCurrencies()
          .then(({ data }) => {
            setCurrencies(data)
            setCurrentCurrency(
              data.find((el) => el.code === tokenData.amount_currency) ?? null,
            )
            res(tokenData)
          })
          .catch((e) => {
            console.error(e)
            setSavedCards([])
            rej(e)
          })
          .finally(() => {
            setCurrenciesInfo((prev) => ({ ...prev, loading: false }))
          })
      } else {
        setSavedCards([])
        res(tokenData)
      }
    })
  }, [])

  const getSessionInfo = useCallback(
    (token: string, tokenData: TokenResponse) => {
      return new Promise<TokenResponse>((res, rej) => {
        Promise.all([
          updateSavedCards(token, tokenData),
          getCurrencies(tokenData),
        ])
          .then(() => {
            res(tokenData)
          })
          .catch((err) => {
            console.error(err)
            rej(err)
          })
      })
    },
    [],
  )

  // При выборе метода обноавляем значение в данных
  useEffect(() => {
    setSessionData((prev) =>
      prev ? { ...prev, payment_method_id: method?.id } : null,
    )
    if (method) {
      setSelectedFields(fields[method.id] ?? [])
      setSelectedFieldsLoading(false)
    }
  }, [method])

  useEffect(() => {
    if (!!sessionData?.payment_method_id && !!sessionData?.payment_system_id) {
      const methodId = sessionData.payment_method_id

      if (!fields[methodId]) {
        //отправляем запрос за полями для формы
        setSelectedFieldsLoading(true)
        apiService.parametrization
          .getAllFormFieldsBySystemAndMethodIds(
            sessionData.payment_system_id,
            methodId,
          )
          .then(({ data }) => {
            setFields((prev) => ({
              ...prev,
              [methodId]: data,
            }))
            setSelectedFields(data)
          })
          .catch((err: AxiosError) => {
            console.error(err)

            const status = err?.response?.status

            if (status && status >= 400 && status < 500) {
              window.location.href = PAYMENT_FAILED_PAGE
            }
          })
          .finally(() => {
            setSelectedFieldsLoading(false)
          })
      } else {
        setSelectedFields(fields[methodId])
        setSelectedFieldsLoading(false)
      }
    }
  }, [sessionData?.payment_method_id, sessionData?.payment_system_id])

  useEffect(() => {
    setSessionData(tokenData)
  }, [tokenData])

  // Перезапускаем данные при изменении данных из токена
  useEffect(() => {
    if (token && sessionData) {
      resetPayment()

      setDataInfo((prev) => ({ ...prev, loading: true }))

      getSelectedMethod()
        .then((result) => {
          return createSession(token, { ...sessionData, ...result })
        })
        .then((result) => {
          return getSessionInfo(token, result)
        })
        .then((result) => {
          const { pathname, searchParams } = matchPaymentPath(result)
          navigate({
            pathname,
            search: new URLSearchParams({
              ...params,
              ...searchParams,
            }).toString(),
          })
        })
        .finally(() => {
          setDataInfo((prev) => ({ ...prev, loading: false }))
        })
    }
  }, [token, sessionData?.order_id, sessionData?.user_id])

  return (
    <PaymentContext.Provider
      value={{
        sessionData,
        createSession: createSession,
        savedCards,
        setSavedCards,
        savedCard,
        setSavedCard,
        contractId,
        setContractId,
        cardForPayment,
        setCardForPayment,
        eventSource,
        fields,
        setFields,
        selectedFields,
        selectMethod,
        selectService,
        method,
        loading: dataInfo.loading,
        savedCardsLoading,
        currencies,
        currentCurrency,
        currenciesLoading: currenciesInfo.loading,
        selectedFieldsLoading,
        disableSessionLeftTimer,
        closeEventSource: close,
      }}
    >
      <PaymentSessionTimerContextProvider paymentTTL={secondsToLeft}>
        {children}
      </PaymentSessionTimerContextProvider>
    </PaymentContext.Provider>
  )
}

export const usePaymentContext = () => useContext(PaymentContext)
